import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { PDFDownloadLink } from '@react-pdf/renderer';
import cogoToast from 'cogo-toast';
import React, { useContext, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import ButtonIcon from '../../components/common/ButtonIcon';
import MainTable from '../../components/tables/MainTable';
import { urlApi } from '../../configuracion/serverConfig';
import { headers } from "../../helpers/authSesion";
import { formatterId } from '../../helpers/formatters';
import numToWord from '../../helpers/numToWord';
import { currencyFormat } from '../../helpers/tableFormatters';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import { creditServices } from '../../services';
import RecaudoInvoice from '../recaudar/invoice/RecaudoInvoice';
import CreditGeneralData from './sections/CreditGeneralData';
import CreditInfo from './sections/CreditInfo';
import { StateContext } from './StateContext';

const CreditDetails = () => {
  const context = useContext(StateContext);
  const { loading, creditSelected: selected, handleRefreshPrestamo } = context;
  const [paymentsPlan, setPaymentsPlan] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDescuentos, setShowDescuentos] = useState(false);
  const [invoiceProps, setInvoiceProps] = useState();
  const [descuentos, setDescuentos] = useState([]);

  useEffect(() => {
    if (!selected) return;
    getCreditPlan();
  }, [selected])

  const generaRecibo = (pago) => {
    setInvoiceProps({
      client: selected.nomCliente,
      clientTypeId: selected.tipoIdCliente,
      clientId: formatterId(Number(selected.idCliente)),
      serial: "RC-" + pago.movimiento,
      ciudad: 'MAICAO - LA GUAJIRA',
      idCredit: selected.idCredito,
      amount: currencyFormat(pago.valorRecaudo || 0),
      concept: pago.concepto,
      sumWord: numToWord(pago.valorRecaudo),
      checkCash: pago.modalidadPago == 1,
      checkWireTransfer: pago.modalidadPago == 2,
      checkPSE: false,
      checkCheque: pago.modalidadPago == 3,
      date: pago.fecha.split('-'),
      observations: pago.observacion
    });
    setShowModal(true);
  };

  const detalle = (index) => {
    var ele;
    while (ele = document.getElementById("row" + index++)) {
      ele = ele.parentElement.parentElement;
      ele.style.display = ele.style.display == "none" ? "" : "none";
    }
  };

  const columns = [
    {
      dataField: 'periodo',
      text: 'Cuota',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (value, pago, index) => {
        return <div id={"row" + (value ? -index : index)}>
          {value ? ([
            value,
            paymentsPlan[index + 1] && !paymentsPlan[index + 1].periodo && <IconButton onClick={() => detalle(index + 1)}>
              <FontAwesomeIcon style={{ width: '16px' }} icon={faEye} color="SteelBlue" />
            </IconButton>]) : <></>}
        </div>;
      },
    },
    {
      dataField: 'fechaCorte',
      headerClasses: 'border-0 text-left',
      text: 'Fecha Corte',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha Pago',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'concepto',
      headerClasses: 'border-0 text-left',
      text: 'Concepto',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'movimiento',
      headerClasses: 'border-0 text-left',
      text: 'No. comprobante',
      formatter: (value, pago) => {
        return <div>
          {value ? ([
            "RC-" + value,
            <IconButton onClick={() => generaRecibo(pago)}>
              <FontAwesomeIcon style={{ width: '16px' }} icon={faEye} color="SteelBlue" />
            </IconButton>]) : <></>}
        </div>;
      },
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'valorRecaudo',
      headerClasses: 'border-0 text-right',
      text: 'Valor Pagado',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'abonoInteres',
      headerClasses: 'border-0 text-right',
      text: 'Interés',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'abonoCapital',
      headerClasses: 'border-0 text-right',
      text: 'Capital',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'descuento',
      headerClasses: 'border-0 text-right',
      text: 'Descuento',
      formatter: (value) => value ? [
        currencyFormat(value),
        <IconButton onClick={() => setShowDescuentos(true)}>
          <FontAwesomeIcon style={{ width: '16px' }} icon={faEye} color="SteelBlue" />
        </IconButton>] : "",
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'saldoProyectado',
      headerClasses: 'border-0 text-right pr-3',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle pr-3',
    },
  ]

  const getCreditPlan = async () => {
    const resp = await creditServices.getCreditPayments(selected.prestamo);
    if (resp.error) return;
    setPaymentsPlan(resp.objResponse);
    fetch(urlApi + '/descuento/' + selected.prestamo, {
      method: 'GET',
      headers
    }).then(res => res.json()).then(data => {
      setDescuentos(data);
    }).catch(err => {
      console.log('err', err);
      toast.error(err);
    });
  };

  const toggleModal = () => {
    setShowModal(false);
  }

  const borrarDescuento = des => {
    if (document.styleSheets[0].cssRules[0].selectorText != ".react-confirm-alert-overlay") {
      document.styleSheets[0].insertRule('.react-confirm-alert-overlay { z-index: 9999 !important; }', 0);
    }
    confirmAlert({
      title: 'Eliminar descuento',
      message: '¿Confirma eliminar el descuento por ' + des.monto + '?',
      buttons: [{
        label: 'Sí',
        onClick: () => {
          fetch(urlApi + '/descuento/' + des.id, {
            method: 'DELETE',
            headers,
          }).then(data => {
            cogoToast.info("Se eliminó el descuento satisfactoriamente", { position: 'bottom-right' });
            handleRefreshPrestamo();
          }).catch(err => {
            console.log('err', err);
            cogoToast.error(err);
          });
        }
      }, {
        label: 'No',
      }]
    });
  };

  const toggleDescuentos = () => {
    var tabla = document.getElementById("tablaDescuentos");
    var head = tabla.tHead.style;
    var body = tabla.tBodies[0].style;
    if (head.display == "none") {
      head.display = "";
      body.display = "";
    } else {
      head.display = "none";
      body.display = "none";
    }
  };

  if (loading) {
    return (
      <Row className="py-4 d-flex justify-content-center">
        <Spinner color="primary" />
      </Row>
    )
  }

  return (
    <>
      <Modal isOpen={showModal} size="md" toggle={() => toggleModal()}>
        {invoiceProps && (
          <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
            <PDFDownloadLink document={<RecaudoInvoice title="Recibo de caja" data={invoiceProps} />} fileName={`recibo_caja-${invoiceProps.serial}.pdf`} >
              {({ loading }) =>
                loading ? 'Cargando recibo...' : 'Descargar recibo de caja'
              }
            </PDFDownloadLink>
          </ButtonIcon>)}
      </Modal>
      <ContentWithAsideLayout aside={<CreditGeneralData />} isStickyAside={false}>
        <CreditInfo />
      </ContentWithAsideLayout>
      <MainTable
        loading={!paymentsPlan}
        data={paymentsPlan}
        columns={columns}
        keyField="periodo"
        headerClassName="d-none"
        pageSize={12}
        className='pr-4'
        noSearch
        noAdd
        rowStyle={(row) => {
          if (!row.periodo) {
            return {
              fontStyle: "italic",
              color: "#777",
              display: "none"
            };
          }
        }}
      />
      <Modal isOpen={showDescuentos} size="md" toggle={() => setShowDescuentos(false)}>
        <ModalHeader>Descuentos Comerciales</ModalHeader>
        <ModalBody>
          <table cellPadding={10} style={{ fontSize: "small" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>No.</th>
                <th style={{ textAlign: "center" }}>Monto</th>
                <th style={{ textAlign: "center" }}>Usuario</th>
                <th style={{ textAlign: "center" }}>Fecha registro</th>
              </tr>
            </thead>
            <tbody>
              {descuentos.map((d, i) =>
                <tr>
                  <td style={{ textAlign: "right" }}>{i + 1}</td>
                  <td style={{ textAlign: "right" }}>{currencyFormat(d.monto)}</td>
                  <td>{d.usuario}</td>
                  <td style={{ textAlign: "center" }}>{d.fecha.substring(0, 10)}</td>
                  <td>
                    <Tooltip title="Eliminar">
                      <IconButton onClick={() => borrarDescuento(d)}>
                        <FontAwesomeIcon style={{ width: '12px' }} icon={faTrash} color="red" />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CreditDetails;